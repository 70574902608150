<template>
  <div>
    <div
      class="event-detail severe_asthma"
      v-if="EventDetail && Object.keys(EventDetail).length"
    >
      <div class="content first-banner__content">
        <div class="first-banner">
          <picture>
            <source
              :srcset="require('@/assets/img/severe_asthma/banner_mob.jpg')"
              media="(max-width: 767px)"
            />
            <source
              :srcset="require('@/assets/img/severe_asthma/banner_tab.jpg')"
              media="(max-width: 1219px)"
            />
            <source
              :srcset="require('@/assets/img/severe_asthma/banner.jpg')"
              media="(min-width: 1220px)"
            />
            <img
              class="first-banner__image"
              :src="require('@/assets/img/severe_asthma/banner.jpg')"
            />
          </picture>
          <div class="first-banner__text">
            <div class="first-banner__title">
              <span>Вершина,</span> открытая каждому.
            </div>
            <div class="first-banner__description">
              Форум экспертов — Новая эра в терапии тяжёлой астмы
            </div>
            <div
              class="button first-banner__button button_orange"
              @click="toRegister(true)"
              v-if="!Object.keys(user).length"
            >
              Зарегистрироваться
            </div>
            <div
              class="button first-banner__button button_orange"
              @click="openIframe()"
              v-else-if="!Object.keys(timerResult).length"
            >
              Смотреть
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="event-detail__timer mb-0 mt-8">
          <!-- <div class="event-detail__timer-title">
            {{
              !Object.keys(timerResult).length
                ? "Смотреть запись вебинара"
                : timerResult.months
                ? "Подключение к трансляции:"
                : "Трансляция начнется через:"
            }}
          </div> -->
          <div
            class="event-detail__timer-date mt-sm-4"
            v-if="timerResult.months"
          >
            {{ $m(EventDetail.date).format("DD MMMM") }}
            в
            {{
              $m
                .utc(EventDetail.date)
                .utcOffset(+3)
                .subtract(10, "minutes")
                .format("HH:mm")
            }}
            (МСК)
          </div>
          <div
            v-else-if="Object.keys(timerResult).length"
            class="event-detail__timer-row"
          >
            <div class="event-detail__timer-item" v-if="timerResult.months">
              <div class="timer__value">{{ timerResult.months }}</div>
              <div class="timer__text">Месяцев</div>
            </div>
            <div
              class="event-detail__timer-item pl-0"
              v-if="timerResult.days > 0"
            >
              <div class="timer__value">{{ timerResult.days }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.days, "день", "дня", "дней") }}
              </div>
            </div>
            <div class="event-detail__timer-item" v-if="timerResult.hours > 0">
              <div class="timer__value">{{ timerResult.hours }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.hours, "час", "часа", "часов") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.minutes > 0"
            >
              <div class="timer__value">{{ timerResult.minutes }}</div>
              <div class="timer__text">
                {{ getNoun(timerResult.minutes, "минуту", "минуты", "минут") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.seconds > 0"
            >
              <div class="timer__value">{{ timerResult.seconds }}</div>
              <div class="timer__text">
                {{
                  getNoun(timerResult.seconds, "секунду", "секунды", "секунд")
                }}
              </div>
            </div>
          </div>
          <div
            v-if="!Object.keys(timerResult).length"
            @click="openIframe()"
            class="event-detail__translation button button_white-blue px-6"
          >
            Смотреть
          </div>
        </div>
        <div class="event-detail__date-info" v-if="false">
          <div class="event-detail__date-text">
            <div class="event-detail__date-item" v-if="EventDetail.date">
              <div class="event-detail__date-item-title">Дата и время</div>
              <div class="event-detail__date-item-value">
                6 апреля 9:30 – 15:00 (мск)
              </div>
            </div>
            <div class="event-detail__date-item" v-if="EventDetail.place">
              <div class="event-detail__date-item-title">Место</div>
              <div
                class="event-detail__date-item-value"
                v-html="EventDetail.place"
              ></div>
            </div>
            <div class="event-detail__date-item" v-if="EventDetail.place">
              <div class="event-detail__date-item-title">Формат</div>
              <div class="event-detail__date-item-value">
                {{ EventDetail.types[0].name }}
              </div>
            </div>
            <div
              class="button event-detail__date-button button_orange"
              @click="toRegister(true)"
              v-if="!Object.keys(user).length"
            >
              Зарегистрироваться
            </div>
          </div>
          <div class="event-detail__date-btn">
            <div class="event-detail__date-btn_title">
              Посмотрите схему проезда:
            </div>
            <div class="event-detail__date-btn_row">
              <a href="https://storage.yandexcloud.net/az-most.ru-storage/media/uploads/asthma-docs/shattle.pdf" target="_blank" class="event-detail__date-btn_button">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.96973 17.491H17.2855M3.07465 17.491H1.91226C1.40843 17.491 1 17.0826 1 16.5788V7.91227C1 7.40844 1.40843 7 1.91226 7H21.0492C21.4643 7 21.8271 7.28029 21.9319 7.68199L23.0929 12.1323C23.1125 12.2075 23.1224 12.2848 23.1224 12.3626V16.5788C23.1224 17.0826 22.714 17.491 22.2101 17.491H21.1142"
                    stroke="currentColor"
                  />
                  <mask id="path-2-inside-1_546_1442" fill="white">
                    <rect
                      x="2.14062"
                      y="8.14014"
                      width="6.38585"
                      height="4.56132"
                      rx="0.456132"
                    />
                  </mask>
                  <rect
                    x="2.14062"
                    y="8.14014"
                    width="6.38585"
                    height="4.56132"
                    rx="0.456132"
                    stroke="currentColor"
                    stroke-width="2"
                    mask="url(#path-2-inside-1_546_1442)"
                  />
                  <mask id="path-3-inside-2_546_1442" fill="white">
                    <rect
                      x="9.43896"
                      y="8.14014"
                      width="6.38585"
                      height="4.56132"
                      rx="0.456132"
                    />
                  </mask>
                  <rect
                    x="9.43896"
                    y="8.14014"
                    width="6.38585"
                    height="4.56132"
                    rx="0.456132"
                    stroke="currentColor"
                    stroke-width="2"
                    mask="url(#path-3-inside-2_546_1442)"
                  />
                  <path d="M5.3335 8.36816V12.7014" stroke="currentColor" />
                  <path d="M12.6313 8.36816V12.7014" stroke="currentColor" />
                  <circle
                    cx="4.87685"
                    cy="17.7191"
                    r="1.78066"
                    stroke="currentColor"
                  />
                  <circle
                    cx="19.0175"
                    cy="17.7191"
                    r="1.78066"
                    stroke="currentColor"
                  />
                  <path
                    d="M21.9823 8.36816H18.1051C17.6013 8.36816 17.1929 8.7766 17.1929 9.28043V11.8897C17.1929 12.2455 17.3998 12.5688 17.7228 12.718L19.9758 13.7578C20.0957 13.8131 20.2261 13.8418 20.3581 13.8418H22.8945"
                    stroke="currentColor"
                  />
                  <path
                    d="M16.9648 14.5264H18.1052"
                    stroke="currentColor"
                    stroke-linecap="round"
                  />
                </svg>
                На шаттле
              </a>
              <a href="https://storage.yandexcloud.net/az-most.ru-storage/media/uploads/asthma-docs/yourself.pdf" target="_blank" class="event-detail__date-btn_button">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.8184 9.5459L15.39 10.8556C15.4332 10.8916 15.479 10.9241 15.5271 10.953L16.3958 11.4742C16.6581 11.6316 16.8185 11.915 16.8185 12.2208V12.2208C16.8185 12.7017 16.4287 13.0916 15.9478 13.0916H15.7718C15.5651 13.0916 15.3631 13.0304 15.1911 12.9158L13.8184 12.0006"
                    stroke="currentColor"
                    stroke-linecap="round"
                  />
                  <path
                    d="M11.9094 15.546L11.6716 17.2103C11.6486 17.3715 11.5897 17.5256 11.4994 17.6612L11.1656 18.1617C11.1162 18.2359 11.0578 18.3038 10.9918 18.3638L8.5655 20.5695C8.2795 20.8295 7.86308 20.887 7.51737 20.7141V20.7141C7.20029 20.5556 7 20.2315 7 19.877V19.5436C7 19.2543 7.11494 18.9768 7.31954 18.7722L9.40789 16.6838C9.61249 16.4792 9.72743 16.2017 9.72743 15.9124V14.4551"
                    stroke="currentColor"
                    stroke-linecap="round"
                  />
                  <circle
                    cx="12.1824"
                    cy="5.18194"
                    r="1.68194"
                    stroke="currentColor"
                  />
                  <path
                    d="M9.1651 10.7047L9.84696 10.1592C10.0186 10.0219 10.2729 10.1441 10.2729 10.3639V13.1309C10.2729 13.4518 10.4142 13.7564 10.6592 13.9637L13.585 16.4394C13.7378 16.5687 13.852 16.7374 13.9153 16.9273L14.9924 20.1585C15.1098 20.5108 15.407 20.7731 15.7711 20.8459C16.2286 20.9374 16.6908 20.7114 16.8994 20.2941L16.9106 20.2716C17.0272 20.0386 17.0544 19.771 16.9873 19.5192L16.0292 15.9262C16.0101 15.8548 15.9839 15.7855 15.9508 15.7194L15.5342 14.8862C15.4818 14.7813 15.4128 14.6856 15.3298 14.6026L14.1381 13.4109C13.9335 13.2063 13.8186 12.9288 13.8186 12.6395V9.45212C13.8186 9.16278 13.7036 8.88529 13.499 8.68069L13.2731 8.45474L12.2898 7.66807C12.0477 7.47442 11.7347 7.39289 11.4289 7.44385L10.5647 7.58788C10.3736 7.61973 10.1945 7.70188 10.0457 7.8259L7.39255 10.0368C7.14382 10.2441 7 10.5511 7 10.8749V12.7174C7 12.9568 7.09513 13.1865 7.26447 13.3558C7.58221 13.6736 8.08525 13.7093 8.44473 13.4397L8.51025 13.3906C8.76139 13.2022 8.9092 12.9066 8.9092 12.5927V11.2371C8.9092 11.03 9.00336 10.8341 9.1651 10.7047Z"
                    stroke="currentColor"
                    stroke-linecap="round"
                  />
                </svg>
                Пешком
              </a>
              <a href="https://storage.yandexcloud.net/az-most.ru-storage/media/uploads/asthma-docs/car.pdf" target="_blank" class="event-detail__date-btn_button">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.8438 10.2588V12.6126" stroke="currentColor" />
                  <path
                    d="M21.329 12.1851C21.115 12.613 20.8154 13.5546 21.329 13.897C21.8425 14.2393 22.8269 13.897 23.2549 13.683"
                    stroke="currentColor"
                  />
                  <path
                    d="M16.193 12.6128H4.6377C4.81211 11.3012 7.04807 10.3955 7.84749 10.2589C9.08296 10.1906 11.025 10.095 12.7692 10.2589C14.5134 10.4229 15.9023 11.998 16.193 12.6128Z"
                    stroke="currentColor"
                  />
                  <path
                    d="M4.18851 15.8049L2.49791 15.8227C1.9986 15.7514 1 15.2663 1 13.8968C1 12.1849 1.21399 11.757 2.06993 11.543C2.90032 11.3354 3.7105 11.4003 3.99581 11.3289C4.35245 10.7583 5.62211 9.53142 7.84757 9.18904C10.073 8.84667 12.4839 9.04639 13.4112 9.18904C14.1245 9.33173 15.8507 10.0023 17.049 11.543C18.6182 11.543 21.9707 11.8426 22.8266 13.0409C23.6826 14.2392 23.1833 15.2521 22.8266 15.6087L20.4229 15.634M7.98418 15.7649L16.6805 15.6734"
                    stroke="currentColor"
                  />
                  <circle
                    cx="6.13559"
                    cy="15.8231"
                    r="1.85385"
                    stroke="currentColor"
                  />
                  <circle
                    cx="18.5472"
                    cy="15.8231"
                    r="1.85385"
                    stroke="currentColor"
                  />
                </svg>
                На машине
              </a>
            </div>
          </div>
        </div>
        <div class="second-banner mt-8">
          <div class="second-banner__text">
            <div class="second-banner__title">
              Почувствуйте свободу возможностей
            </div>
            <div class="second-banner__description">
              Форум объединит ведущих врачей-пульмонологов и аллергологов,
              предоставит платформу, на которой вы сможете поделиться своим
              клиническим опытом, открыто обсудить актуальные вопросы
              биологической терапии тяжёлой бронхиальной астмы — во благо
              пациентов.
            </div>
            <div class="second-banner__button-container">
              <div
                class="button second-banner__button button_orange"
                @click="copyLink"
              >
                Пригласить коллегу
              </div>
              <div
                class="button second-banner__button button_orange"
                @click="scrollTo('programm')"
              >
                Программа мероприятия
              </div>
            </div>
          </div>
          <picture>
            <source
              :srcset="require('@/assets/img/severe_asthma/banner2_mob.jpg')"
              media="(max-width: 767px)"
            />
            <source
              :srcset="require('@/assets/img/severe_asthma/banner2_tab.jpg')"
              media="(max-width: 1219px)"
            />
            <source
              :srcset="require('@/assets/img/severe_asthma/banner2.jpg')"
              media="(min-width: 1220px)"
            />
            <img
              class="second-banner__image"
              :src="require('@/assets/img/severe_asthma/banner2.jpg')"
            />
          </picture>
        </div>
        <DefaultSlider
          :color="'#2186AF'"
          :title="'Наши эксперты'"
          :settings="settingsSlider"
          id="experts"
        >
          <template #items>
            <div
              class="slide-lector"
              v-for="(lector, ind) in lectors"
              :key="ind"
            >
              <div class="slide-lector__image-container">
                <img class="slide-lector__image" :src="lector.image" />
              </div>
              <div class="slide-lector__tag" v-html="lector.tag"></div>
              <div class="slide-lector__name" v-html="lector.name"></div>
              <div
                class="slide-lector__description"
                v-html="lector.description"
              ></div>
            </div>
          </template>
        </DefaultSlider>
        <div class="event-detail__main-info mt-8" id="programm">
          <InfoDrop
            v-for="(dropdown, ind) in program"
            :key="ind"
            @openInner="openInner(dropdown.title)"
            :active="true"
            :item="{
              title: `<div class='programm-list__title'>${dropdown.title}</div><div class='programm-list__descr'>${dropdown.descr}</div>`,
              content: programmHTML(dropdown.items),
            }"
          />
        </div>
        <div class="buttons mt-6">
          <div
            class="button first-banner__button button_orange d-none d-xl-flex"
            @click="toRegister(true)"
            v-if="!Object.keys(user).length"
          >
            Зарегистрироваться
          </div>
          <div
            class="button first-banner__button button_orange d-none d-xl-flex"
            @click="openIframe()"
            v-else-if="!Object.keys(timerResult).length"
          >
            Смотреть
          </div>
          <div
            class="button first-banner__button button_orange d-md-none"
            @click="toRegister(true)"
            v-if="!Object.keys(user).length"
          >
            Зарегистрироваться
          </div>
          <div
            class="button first-banner__button button_orange d-md-none"
            @click="openIframe()"
            v-else-if="!Object.keys(timerResult).length"
          >
            Смотреть
          </div>
        </div>
        <InfoDrop
          @openInner="openInner"
          :item="{
            title: 'Список сокращений',
            content: shortList,
          }"
        />
        <InfoDrop
          @openInner="openInner"
          :item="{
            title: 'Список литературы',
            content: litList,
          }"
        />
      </div>
      <LibrarySlider
        v-if="sliderItems && sliderItems.length && !sliderItems.includes(null)"
        :items="sliderItems"
        :color="'#2186AF'"
        :title="'Это может быть полезным'"
        @clickMaterials="clickMaterials"
      />
    </div>
    <div v-else-if="EventDetail === 404"><Choch class="inPage" /></div>
    <div v-else-if="EventDetail === false"><Error class="inPage" /></div>
    <Facecast
      :slug="$route.meta.slug || 'severe_asthma'"
      :record="'https://storage.yandexcloud.net/az-most.ru-storage/media/content/severe_asthma/%D0%A4%D0%BE%D1%80%D1%83%D0%BC%20%D1%8D%D0%BA%D1%81%D0%BF%D0%B5%D1%80%D1%82%D0%BE%D0%B2.%20%D0%9D%D0%BE%D0%B2%D0%B0%D1%8F%20%D1%8D%D1%80%D0%B0%20%D0%B2%20%D1%82%D0%B5%D1%80%D0%B0%D0%BF%D0%B8%D0%B8%20%D0%A2%D0%91%D0%90-min.mp4'"
      @closeFrame="openFrame = false"
      :open="openFrame"
    />
    <PopupEnd
      :eventName="'severe_asthma0406'"
      :backUrl="{ name: 'SevereAsthma' }"
      @close="closePopup"
      v-if="popup"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Breadcrumbs from "../../components/pageComponents/Breadcrumbs.vue";
import InfoDrop from "../../components/pageComponents/InfoDrop.vue";
import PopupEnd from "@/components/pageComponents/PopUpEventEnd.vue";
import LibrarySlider from "@/components/pageComponents/LibrarySlider.vue";
import DefaultSlider from "@/components/pageComponents/DefaultSlider.vue";
import Facecast from "@/components/pageComponents/Facecast.vue";
import Choch from "@/views/404.vue";
import Error from "@/views/Error.vue";
import PopUpDefault from "@/components/pageComponents/PopUpDefault.vue";
export default {
  metaInfo() {
    if (this.EventDetail.name) {
      return {
        title: "Az-most - " + this.EventDetail.name.replace(/<[^>]*>?/gm, ""),
      };
    } else {
      return { title: "Az-most - " + this.EventDetail.name };
    }
  },
  name: "SevereAsthma",
  components: {
    Breadcrumbs,
    InfoDrop,
    Choch,
    Error,
    PopupEnd,
    PopUpDefault,
    LibrarySlider,
    DefaultSlider,
    PopUpDefault,
    Facecast,
  },
  data: () => ({
    popup: false,
    popupActivity: false,
    popupType: "",
    openFrame: false,
    timerResult: { id: 0 },
    timerResultEnd: {},
    interval: null,
    percent: null,
    settingsSlider: {
      slidesToShow: 4,
      draggable: false,
      infinite: true,
      responsive: [
        {
          breakpoint: 1220,
          settings: {
            slidesToShow: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            infinite: true,
            variableWidth: true,
          },
        },
      ],
    },
  }),
  computed: {
    ...mapGetters([
      "user",
      "TextList",
      "VideoList",
      "PodcastsList",
      "Projects",
    ]),
    sliderItems() {
      let arr = [];
      // if (this.PodcastsList && this.PodcastsList.length) {
      //   arr = arr.concat(
      //     this.PodcastsList.map((el) => ({ ...el, page: "PodcastDetail" }))
      //   );
      // }
      // if (this.TextList && this.TextList.length) {
      //   arr = arr.concat(
      //     this.TextList.map((el) => ({ ...el, page: "TextDetail" }))
      //   );
      // }
      if (this.VideoList && this.VideoList.length) {
        arr = arr.concat(
          this.VideoList.map((el) => ({ ...el, page: "VideoDetail" })).filter(
            (video) => video.slug === "mekhanizm-preparata-tezspire"
          )
        );
        // .filter((video) => video.slug === 'petunina-n-a-inglt-2-sravnenie-osnovnyh-predstavitelej-klassa')
      }
      // if (this.Projects && this.Projects.length) {
      //   arr = arr.concat(
      //     this.Projects.map((el) => ({ ...el, component: "project" }))
      //   );
      // }
      return arr;
    },
    shortList() {
      return `РАН – Российская академия наук<br><br>
ТБА – тяжелая бронхиальная астма`;
    },
    litList() {
      return `<ol><li>Инструкция по медицинскому применению лекарственного препарата Тезспире 110 мг/мл  (раствор для подкожного введения). Регистрационное удостоверение ЛП-N002978 от 10.08.2023. <a target="_blank" href="https://grls.rosminzdrav.ru/Grls_View_v2.aspx?routingGuid=d47d47b6-f8be-4141-9435-a87814c6bd95">https://grls.rosminzdrav.ru/Grls_View_v2.aspx?routingGuid=d47d47b6-f8be-4141-9435-a87814c6bd95</a></li></ol>`;
    },
    EventDetail() {
      return {
        id: 430,
        types: [
          {
            id: 1,
            name: "Очно / Онлайн",
            code: "online",
          },
        ],
        nosology: [
          {
            id: 6,
            title: "Нефрология",
            slug: "nephrology",
            icon: "https://storage.yandexcloud.net/az-most.ru-storage/media/uploads/nosology/icons/2022/07/13/Ellipse_193.svg",
            active: true,
          },
        ],
        lector: [],
        name: "Тезспире. Один ответ на сложные вызовы",
        description: "",
        banner_description: "",
        additional_info: "",
        date: this.$route.meta.date || "2024-04-06T09:30:00+03:00",
        date_end: "2024-04-06T15:00:00+03:00",
        place:
          "Москва, Ленинградское ш., 39, стр. 1, DoubleTree by Hilton Moscow - Marina, Зал Секвойя",
        hash: "30b657200228001ef4d4aa0f6c654699b1867f629ef6cb0594cf0b21538cf568",
        webinar_id: "",
        room_id: "",
        external: false,
        external_link: "",
        is_active: true,
        preview_mobile: null,
        preview_tablet: null,
        preview_desktop: null,
        banner_mobile: null,
        banner_tablet: null,
        banner_desktop: null,
        banner_desktop_l: null,
        banner_desktop_xl: null,
        banner_desktop_xxl: null,
      };
    },
    lectors() {
      return [
        {
          image: require("@/assets/img/severe_asthma/experts/1.jpg"),
          tag: "Спикер",
          name: "Авдеев Сергей Николаевич",
          description:
            "академик РАН, д.м.н., профессор, зав. кафедрой пульмонологии, руководитель клиники пульмонологии и респираторной медицины ИКМ ФГАОУ ВО Первый МГМУ им. И.М. Сеченова; главный внештатный специалист пульмонолог Министерства здравоохранения РФ, Москва",
        },
        // {
        //   image: require("@/assets/img/severe_asthma/experts/2.jpg"),
        //   tag: "Спикер",
        //   name: "Белевский Андрей Станиславович",
        //   description:
        //     "д.м.н., профессор, главный внештатный пульмонолог Департамента здравоохранения г. Москвы, заведующий кафедрой пульмонологии ФГАОУ ВО РНИМУ им. И.И.Пирогова, Председатель Московского отделения Российского респираторного общества, президент Российского респираторного общества",
        // },
        {
          image: require("@/assets/img/severe_asthma/experts/3.jpg"),
          tag: "Спикер",
          name: "Курбачева Оксана Михайловна",
          description:
            'д.м.н., проф., зав. отделением бронхиальной астмы Федерального государственного бюджетного учреждени Государственный научный центр "Институт иммунологии" Федерального медико-биологического агентства России, Москва',
        },
        {
          image: require("@/assets/img/severe_asthma/experts/4.jpg"),
          tag: "Спикер",
          name: "Ненашева Наталья Михайловна",
          description:
            "д.м.н., профессор, зав. кафедрой аллергологии и иммунологии  ФГБОУ ДПО РМАНПО МЗ РФ, Москва",
        },
        {
          image: require("@/assets/img/severe_asthma/experts/5.jpg"),
          tag: "Спикер",
          name: "Емельянов Александр Викторович",
          description:
            "Заведующий кафедрой пульмонологии, доктор медицинских наук, профессор, Северо-Западный государственный медицинский университет им. И.И. Мечникова, Санкт-Петербург",
        },
        {
          image: require("@/assets/img/severe_asthma/experts/6.jpg"),
          tag: "Спикер",
          name: "Кузубова Наталия Анатольевна",
          description:
            "д.м.н., руководитель экспертного центра по профилю пульмонология ГМБ №2, заместитель директора НИИ пульмонологии ПСПбГМУ им. акад. И.П. Павлова",
        },
        {
          image: require("@/assets/img/severe_asthma/experts/7.jpg"),
          tag: "Спикер",
          name: "Княжеская Надежда Павловна",
          description:
            "к.м.н.,  доцент кафедры пульмонологии ФДПО ФГБОУ ВО РНИМУ им. Н.И. Пирогова Минздрава России",
        },
      ];
    },
    program() {
      return [
        {
          title: "Программа на 6 апреля 2024 г.",
          descr: "(указано московское время)",
          items: [
            {
              time: "09:30 – 10:00",
              title: "Регистрация участников",
              icon: "talking.svg",
            },
            {
              time: "10:00 – 10:10",
              title: "Открытие",

              icon: "talking.svg",
            },
            {
              time: "10:10 – 10:30",
              title: "Новая эра в терапии ТБА: Встрейчайте Тезспире",
              description:
                "Авдеев Сергей Николаевич, академик РАН, проф., д.м.н.",
              icon: "person.svg",
            },
            {
              time: "10:30 – 11:30",
              title: "Встреча на вершине: экспертное мнение",
              icon: "person.svg",
              description:
                "Авдеев Сергей Николаевич, академик РАН, проф., д.м.н. <br>Ненашева Наталья Михайловна, проф., д.м.н. <br>Курбачева Оксана Михайловна, проф., д.м.н. <br>Емельянов Александр Викторович, проф., д.м.н.",
            },
            {
              time: "11:30 – 12:00",
              title: "Покорение вершины: открытая дискуссия",
              icon: "talking.svg",
              description: "Все спикеры",
            },
            {
              time: "12:00 – 13:00",
              title: "Перерыв",
              icon: "coffee.svg",
            },
            {
              time: "13:00 – 15:00",
              title:
                "Таргетная терапия широкого профиля: новые возможности в практике (Интерактивный разбор клинических случаев)",
              icon: "person.svg",
              description:
                "Авдеев Сергей Николаевич, академик РАН, проф., д.м.н. <br> Княжеская Надежда Павловна, к.м.н. <br>Кузубова Наталия Анатольевна, д.м.н.",
            },
          ],
        },
      ];
    },

    galaxyProgramm() {
      let html = ``;
      let block = ``;
      for (let item of this.program) {
        block += `
              <div class="hobl__program-item">
                <div class="hobl__program-time">${item.time}</div>
                <div class="d-flex align-start hobl__program-info">
                  <img class="mr-2" src="${require(`@/assets/img/forsiga/${item.icon}`)}"  />
                  <div class="hobl__program-info my-auto">
                    <div class="hobl__program-title">
                    ${item.title}
                    </div>
                    <div style="${
                      item.description ? "" : "display: none"
                    }" class="hobl__program-description">
                      ${item.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>`;
      }
      html = `<div class="hobl__program mb-8">${block}</div>`;
      return html;
    },
  },
  methods: {
    ...mapActions([
      "fetchEventsCalendar",
      "fetchTextMaterials",
      "fetchVideoMaterials",
      "fetchPodcasts",
      "fetchProjects",
      "fetchAlerts",
    ]),
    copyLink() {
      var copyText = `${window.location.href}`;
      navigator.clipboard.writeText(copyText);
      this.fetchAlerts({
        alerts: "Ссылка скопирована в буфер обмена",
        type: "notify",
      });
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "asthma", {
          asthma: {
            "invite colleague": {
              ...this.$root.ymFields,
            },
          },
        });
      }
    },
    scrollTo(selector) {
      if (selector === "programm") {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "asthma", {
            asthma: {
              "programm click": {
                ...this.$root.ymFields,
              },
            },
          });
        }
      }
      this.activeTab = parseInt(selector.replace(/^\D+/g, "")) - 1;
      const section = document.querySelector("#" + selector);

      // определяем отступ от верхнего края всей страницы (не области просмотра)
      const multyplier = this.$root.isMobile
        ? window.innerHeight
        : window.innerHeight / 2;
      const position = Math.round(section.offsetTop) + multyplier;

      // прокручиваем область просмотра до нужной позиции
      scrollTo({
        top: position,
        // плавно
        behavior: "smooth",
      });
    },
    clickMaterials(name) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "forsiga", {
          forsiga: {
            "material click": {
              [name]: {
                ...this.$root.ymFields,
              },
            },
          },
        });
      }
    },
    async toRegister(reg_button) {
      if (reg_button) {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "severe_asthma", {
            severe_asthma: {
              "reg click": {
                ...this.$root.ymFields,
              },
            },
          });
        }
      }
      localStorage.setItem("source", `severe_asthma0406`);
      localStorage.setItem("backUrl", JSON.stringify({ name: "SevereAsthma" }));
      // const route = this.$router.resolve({
      //   name: "Register",
      //   query: { email: this.$route.query.email || "" },
      // });
      // window.open(route.href, "_blank");
      window.open(this.$root.ssoLinks.registration, "_self");
    },
    programmHTML(items) {
      let html = ``;
      let block = ``;
      for (let item of items) {
        block += `
              <div class="hobl__program-item">
                <div class="hobl__program-time">${item.time}</div>
                <div class="d-flex align-start hobl__program-info">
                  <img class="mr-4" src="${require(`@/assets/img/severe_asthma/${item.icon}`)}"  />
                  <div class="hobl__program-info my-auto">
                    <div class="hobl__program-title">
                    ${item.title}
                    </div>
                    <div style="${
                      item.description ? "" : "display: none"
                    }" class="hobl__program-description">
                      ${item.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>`;
      }
      html = `<div class="hobl__program mb-8">${block}</div>`;
      return html;
    },
    openInner(programm) {
      if (programm) {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "severe_asthma", {
            forsiga: {
              "programm block click": {
                ...this.$root.ymFields,
              },
            },
          });
        }
      }
    },
    timer() {
      let date = new Date();
      const dateEnd = this.$m
        .utc(this.EventDetail.date)
        .utcOffset(+3)
        .subtract(10, "minutes");
      const today = this.$m.utc(date).utcOffset(+3);
      const diff = today.diff(dateEnd, "seconds");
      this.$set(this, "timerResult", {});
      if (diff > 0) return;
      let months = Math.abs(diff) / 2592000;
      let fullMonths = Math.floor(months);
      let days = (months - Math.floor(months)) * 30;
      let fullDays = Math.floor(days);
      let hours = (days - Math.floor(days)) * 24;
      let fullHours = Math.floor(hours);
      let minutes = (hours - Math.floor(hours)) * 60;
      let fullMinutes = Math.floor(minutes);
      let seconds = (minutes - Math.floor(minutes)) * 60;
      let fullSeconds = Math.round(seconds);
      this.$set(this, "timerResult", {});
      if (fullMonths) {
        this.$set(this.timerResult, "months", fullMonths);
      }
      if (fullDays) {
        this.$set(this.timerResult, "days", fullDays);
      }
      if (fullHours) {
        this.$set(this.timerResult, "hours", fullHours);
      }
      if (fullMinutes) {
        this.$set(this.timerResult, "minutes", fullMinutes);
      }
      if (fullSeconds) {
        this.$set(this.timerResult, "seconds", fullSeconds);
      }
    },
    async getCalendar(id) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "asthma", {
          asthma: {
            "add to calendar": {
              ...this.$root.ymFields,
            },
          },
        });
      }
      const url = await this.fetchEventsCalendar(id);
      let a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      a.href = url;
      a.download = "event.ics";
      a.click();
      setTimeout(async () => {
        window.URL.revokeObjectURL(url);
      }, 1000);
      a.remove();
    },
    getNoun(number, one, two, five) {
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return five;
      }
      n %= 10;
      if (n === 1) {
        return one;
      }
      if (n >= 2 && n <= 4) {
        return two;
      }
      return five;
    },
    closePopup() {
      this.popup = false;
    },
    webinarEnd(e) {
      if (e.origin !== document.location.origin) {
        if (e.data && e.data === "ld_finish_call") {
          if (
            !localStorage.getItem("token") &&
            !!!Object.keys(this.user).length &&
            !!this.$route.query.access
          ) {
            // this.popup = true;
          }
        }
      }
    },
    openIframe() {
      if (!localStorage.getItem("token") && !!!Object.keys(this.user).length) {
        if (!!!this.$route.query.access) {
          this.popup = true;
        } else {
          // this.openFrame = true;
          this.popup = true;
        }
        // this.openFrame = true;
      } else {
        this.openFrame = true;
      }
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "asthma", {
          asthma: {
            "broadcast watch": {
              ...this.$root.ymFields,
            },
          },
        });
      }
    },
    cyclicVideo(duration) {
      const vm = this;
      this.timer = setTimeout(() => {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "video 30 sec", {
            "video 30 sec": {
              "video name": vm.EventDetail.name,
              "video duration": duration,
              ...this.$root.ymFields,
            },
          });
        }
        vm.cyclicVideo();
      }, 30000);
    },
  },
  created() {
    this.timer();
    this.interval = setInterval(this.timer, 1000);
  },
  async mounted() {
    const vm = this;

    // document.querySelector(
    //   "#app"
    // ).style.backgroundImage = `url(${require("@/assets/img/galaxy/back.jpg")})`;

    // await this.fetchTextMaterials({
    //   disease: ["hbp", "hsn", "diabetes"],
    // });
    await this.fetchVideoMaterials({
      disease: ["severe_asthma"],
    });
    // await this.fetchPodcasts({
    //   disease: ["hbp", "hsn", "diabetes"],
    // });
    // await this.fetchProjects({
    //   disease: ["hbp", "hsn", "diabetes"],
    // });
    if (
      !localStorage.getItem("token") &&
      !!!Object.keys(this.user).length &&
      !!this.$route.query.access &&
      !this.popup
    ) {
      setTimeout(() => {
        // this.popup = true;
      }, 10000);
    }
    window.addEventListener("message", this.webinarEnd, false);
    let video = document.querySelector(".event-detail__timer video");
    if (video) {
      video.addEventListener("play", function () {
        if (this.currentTime === 0) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video start", {
              "video start": {
                "video name": vm.EventDetail.name,
                "video duration": Math.round(this.duration),
                ...vm.$root.ymFields,
              },
            });
          }
        } else {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video resume", {
              "video resume": {
                "video name": vm.EventDetail.name,
                "video duration": Math.round(this.duration),
                ...vm.$root.ymFields,
              },
            });
          }
        }
        vm.cyclicVideo(Math.round(this.duration));
      });
      video.addEventListener("pause", function () {
        if (this.currentTime !== this.duration) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video pause", {
              "video pause": {
                "video name": vm.EventDetail.name,
                "video duration": Math.round(this.duration),
                ...vm.$root.ymFields,
              },
            });
          }
        }
        clearTimeout(vm.timer);
        vm.timer = null;
      });
      video.addEventListener("ended", function () {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "video end", {
            "video end": {
              "video name": vm.EventDetail.name,
              "video duration": Math.round(this.duration),
              ...vm.$root.ymFields,
            },
          });
        }
      });
      video.addEventListener("timeupdate", function () {
        let percent = Math.ceil((this.currentTime / this.duration) * 100);
        if (this.percent !== percent && percent !== 0 && percent % 5 === 0) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video percentage watched", {
              "video percentage watched": {
                "video percentage watched": percent,
                ...vm.$root.ymFields,
              },
            });
          }
          this.percent = percent;
        }
      });
    }
  },
  beforeDestroy() {
    // document.querySelector("#app").style.backgroundImage = "";
    clearInterval(this.interval);
    window.removeEventListener("message", this.webinarEnd, false);
  },
  watch: {
    "$route.params.id": {
      handler: async function () {
        // if (this.$route.params.id && !this.$route.query.access) {
        //   await this.fetchEventDetail({id: this.$route.params.id});
        // }
      },
      deep: true,
      immediate: true,
    },
    async timerResult() {
      if (!Object.keys(this.timerResult).length) {
        clearInterval(this.interval);
      }
    },
    async EventDetail() {
      if (this.EventDetail.external) {
        window.open(this.EventDetail.external_link, "_self");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popup__text {
  font-family: "Roboto Slab", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  color: #656969;
}
.timer {
  &__value {
    margin-right: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
.event-detail {
  padding-top: 64px;
  @media screen and (max-width: 1220px) {
    padding-top: 0px;
  }

  &__tip {
    margin-top: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__description-block {
    margin-bottom: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 48px;
    background-color: #f8f8f8;

    & img {
      height: 220px;
      @media screen and (max-width: 1220px) {
        width: 100%;
        height: auto;
      }

      @media screen and (max-width: 767px) {
        margin: 0 auto;
      }
    }

    @media screen and (max-width: 1220px) {
      display: block;
      padding: 24px;
    }

    @media screen and (max-width: 767px) {
      display: block;
      padding: 16px;
    }
  }

  video {
    width: 100%;
    height: auto;
  }

  &__banner {
    width: 100%;
  }

  &__timer {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 37px;
    border-radius: 16px;
    background: #ff7f32;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    position: relative;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }

    @media screen and (max-width: 767px) {
      padding: 16px;
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      margin-right: auto;

      @media screen and (max-width: 1220px) {
        margin-right: 0;
        margin-bottom: 32px;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-item {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 40px;
      &:last-child {
        margin-right: 0;
        &::after {
          display: none;
        }
      }

      &::after {
        display: block;
        content: "";
        width: 1px;
        height: 50%;
        background-color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }

      @media screen and (max-width: 1220px) {
        padding: 0 15px;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: 0 15px;
      }
    }

    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 1220px) {
        // width: 100%;
      }
    }

    &-date {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 48px;
      color: #ffffff;

      @media screen and (max-width: 767px) {
        font-size: 26px;
      }
    }
  }

  &__iframe {
    position: relative;
    width: 100%;
    display: none;

    & iframe {
      width: 100%;
      height: 800px;

      @media screen and (max-width: 1220px) {
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: 1220px) {
      display: none;
    }
  }

  &__iframe_opened {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    padding: 64px;

    & iframe {
      height: 100%;
    }

    @media screen and (max-width: 1220px) {
      padding: 0;
    }
  }

  &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;

    @media screen and (max-width: 1220px) {
      top: 0;
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__translation {
    max-width: 280px;

    @media screen and (max-width: 1220px) {
      width: auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }

  &__date {
    &-info {
      border-left: 4px solid #ff7f32;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 24px;
      padding: 24px;
      margin-top: 32px;
      margin-bottom: 40px;
      background-color: #f8f8f8;

      @media screen and (max-width: 1220px) {
        flex-direction: column;
        margin-top: 40px;
      }

      @media screen and (max-width: 767px) {
        margin-top: 32px;
      }
    }
    &-btn {
      width: 100%;
      &_row {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        gap: 40px;
        @media screen and (max-width: 1199px){
          gap: 24px;
        }
        @media screen and (max-width: 767px){
          flex-direction: column;
          gap: 16px;
        }
      }
      &_title {
        font-family: 'Roboto Slab', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #B2B4B4;
      }
      &_button {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        min-width: 164px;
        padding: 10px 18px;
        border: 1px solid #ff7f32;
        border-radius: 999px;
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        color: #ff7f32;
        transition: all .1s linear;
        &:hover{
          color: #ffffff;
          background-color: #ff7f32;
        }
         @media screen and (max-width: 767px){
          width: 100%;
          justify-content: center;
         }
      }
    }
  }

  &__about-title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #3c4242;
  }

  &__about-description {
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #656969;
  }

  &__date-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
  }

  &__date-item {
    margin-right: 32px;

    @media screen and (max-width: 1220px) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  &__date-item-title {
    margin-bottom: 4px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__date-item-value {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #3c4242;
  }

  &__add-calendar {
    width: 280px;
    margin-left: auto;
    @media screen and (max-width: 1220px) {
      width: 100%;
    }
  }
}
</style>

<style lang="scss">
.hobl {
  &__program {
    &-item {
      margin-bottom: 48px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    &-time {
      width: fit-content;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #2186af;
      padding: 4px 8px;
      border-radius: 99px;
      // background-color: #634075;
      background-color: #f8f8f8;
      margin-right: auto;

      @media screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }

    &-info {
      width: 75%;

      & > img {
        width: 50px;
        height: 50px;
        margin-right: 15px;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    &-title {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      // color: #ffffff;
      color: #3c4242;
    }

    &-description {
      margin-top: 8px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #656969;
    }
  }

  &-activities {
    &__title {
      margin-bottom: 24px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #1f1f1f;
    }

    &__description {
      margin-bottom: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #3c4242;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 32px;
      row-gap: 32px;

      @media screen and (max-width: 1219px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &__item {
      padding: 40px 22px;
      background: #f8f8f8;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  &-activity {
    &__icon {
      height: 117px;
      margin-bottom: 32px;

      & img {
        height: 100%;
      }
    }

    &__button {
      width: 100%;
      border: 1px solid #2186af;
      color: #2186af;
      background-color: transparent;

      &:hover {
        border-color: #2eb5eb;
        color: #2eb5eb;
      }
    }
  }
}
.event-detail {
  &__warning {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #656969;
    & svg {
      flex-shrink: 0;
    }

    & a {
      text-decoration: underline;
    }
  }
  i {
    font-style: italic !important;
  }

  b,
  strong {
    font-weight: 500 !important;
  }

  ul {
    list-style: disc;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  ol {
    list-style: decimal;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  li {
    margin-bottom: 8px;

    & > ul {
      margin-top: 8px;

      & li {
        color: #656969;
        list-style-type: none;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.first-banner {
  border-radius: 16px;
  overflow: hidden;
  height: 419px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1220px) {
    border-radius: 0;
    flex-direction: column;
    align-items: stretch;
    height: auto;
  }

  @media screen and (max-width: 767px) {
    border-radius: 0;
  }

  &__title {
    color: #1f1f1f;
    font-family: "Roboto Slab", sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    margin-bottom: 24px;

    @media screen and (min-width: 1220px) {
      color: #fff;
    }

    span {
      @media screen and (min-width: 1220px) {
        color: #ff7f32;
      }
    }

    @media screen and (max-width: 767px) {
      order: 2;
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 24px;
      margin-top: 24px;
      span {
        @media screen and (min-width: 1220px) {
          color: #ff7f32;
        }
        // font-size: 21px;
        // line-height: 26px;
      }
    }
  }

  &__button {
    width: 200px;

    @media screen and (max-width: 767px) {
      order: 1;
      width: 100%;
    }
  }

  &__description {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 16px;
    color: #3c4242;
    @media screen and (min-width: 1220px) {
      color: #fff;
    }
    @media screen and (max-width: 767px) {
      order: 3;
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    padding-left: 74px;
    max-width: 592px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    @media screen and (max-width: 1219px) {
      position: static;
      // max-width: none;
      margin-top: 16px;
      padding: 0;
    }

    @media screen and (max-width: 767px) {
      // background-color: #ccd7e0;
      padding: 24px 16px;
      display: flex;
      margin-top: 0;
      flex-direction: column;
    }
  }

  & picture {
    width: auto;
    height: 100%;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
      position: relative;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}

.bottom-banner {
  height: 340px;
  border-radius: 16px;
  padding: 40px 54px;
  position: relative;
  overflow: hidden;
  margin-top: 64px;

  @media screen and (max-width: 1220px) {
    padding: 40px 32px;
    height: auto;
  }

  @media screen and (max-width: 767px) {
    height: 550px;
    padding: 32px;
  }

  &__logo {
    margin-bottom: 32px;

    @media screen and (max-width: 767px) {
      width: 137px;
      margin-bottom: 24px;
    }

    & svg {
      max-width: 100%;
    }
  }

  &__text {
    position: relative;
    max-width: 430px;
  }

  &__description {
    color: #3c4242;

    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__button {
    width: 208px;
    margin-top: 24px;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.slide-lector {
  border-radius: 16px;
  height: 100%;

  &__image-container {
    width: 280px;
    height: 270px;
    margin-bottom: 16px;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__tag {
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 4px 8px;
    background-color: #ff7f32;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #fff;
    width: fit-content;
  }

  &__name {
    margin-bottom: 8px;
    color: #3c4242;
    font-family: "Roboto Slab", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__description {
    color: #b2b4b4;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
}

.default-slider {
  padding: 24px 0;
}

.second-banner {
  position: relative;
  overflow: hidden;
  height: 340px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1219px) {
    flex-direction: column;
    align-items: stretch;
    height: 515px;
    border-radius: 16px;
  }

  @media screen and (max-width: 767px) {
    border-radius: 16px;
    height: auto;
  }

  &__title {
    color: #fff;
    font-family: "Roboto Slab", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    margin-bottom: 16px;
    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }
  &__description {
    margin-bottom: 24px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__button {
    &-container {
      display: flex;
      flex-direction: row;
      @media screen and (max-width: 767px) {
        margin-top: 48px;
        flex-direction: column;
      }
    }
    width: fit-content;
    margin-right: 16px;
    @media screen and (max-width: 767px) {
      margin-bottom: 16px;
    }
    &:last-child {
      margin-right: 0;
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    padding-left: 74px;
    max-width: 589px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    @media screen and (max-width: 1219px) {
      margin-top: auto;
      padding: 24px 16px;
      // position: static;
      // max-width: none;
      // background-color: #dee2ee;
    }
  }

  & picture {
    width: auto;
    height: 100%;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 1219px) {
      width: 100%;
      // height: auto;
      // position: relative;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}
</style>

<style lang="scss">
.severe_asthma .programm-list__descr {
  margin-top: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #b2b4b4;
}

.short-list {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #656969;
}
</style>
